import React, { useEffect, useState } from 'react';
import { Carousel as BootstrapCarousel } from 'react-bootstrap';
import '../static/css/Carousel.css';

function Carousel() {
  const [images, setImages] = useState([]);
  const homePageImageUrl = `${process.env.REACT_APP_API_URL}/site_defs/homepage-image/`;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(homePageImageUrl);
        const datas = await response.json();
        setImages(datas.map(data => data['image']));
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className="carousel-container">
      <BootstrapCarousel interval={3000}>
        {images.map((image, index) => (
          <BootstrapCarousel.Item key={index}>
            <div
              className="carousel-image"
              style={{ backgroundImage: `url(${image})` }}
            />
          </BootstrapCarousel.Item>
        ))}
      </BootstrapCarousel>
    </div>
  );
}

export default Carousel;
